// Import
@import '../../assets/css/index';
.loader-container {
    @include displayFlex(row, wrap, center, center, center);
    height: 100%;
    width: 100%;
}
.loader {
    min-height: 50vh;
    min-width: 100vw;
    position: absolut;
    @include displayFlex(row, wrap, center, center, center);
}