// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

body {
    font-family: $f-medium;
}

// Imagens
img {
    max-width: 100%;
    height: auto;
}

// List
ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

li {
    list-style: none;
}

button {
    border: 0;
}

select, button, input {
    &:focus {
        outline: none;
    }
}

select, button {
    cursor: pointer;
}

// Container
.container {
    width: $widthContainer;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
}


// Title
.title-main {
    h4 {
        margin: 0 0 4px;

        font-family: $f-bold;
        font-size: 14px;
        text-transform: uppercase;
        color: #E4B716;
    }

    h1 {
        margin: 0 0 0px;

        font-family: $f-ultra;
        font-size: 50px;
        text-transform: uppercase;
        color: #545555;
        line-height: 1; 

        &:after {
            content: '';
            width: 25%;
            height: 4px;
            display: block;
            margin-top: 4px;

            background:#D1A710;

            @media(max-width: 820px) {
               margin: 10px auto 0;
            }
        }
    }
}