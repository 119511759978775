
// VARIABLES //
// ** Container
$widthContainer: 1400px;

// Font
$f-medium: 'geomanistmedium';
$f-bold:'geomanistbold';
$f-black: 'geomanistblack';
$f-ultra: 'geomanistultra';

// colors
$c-white: #fff;
$c-blue: #656C84;
$c-dark-blue: #5f6780;
$c-dark-gray: #616161;
$c-light-gray: #dee0e780;
$c-green: #29a36e;
$c-red: #a32c29;
$c-black: #717171;
$c-theme-bg-color: #FCFDFF;
$c-yellow: #e4b716;
$c-dark-yellow:#f5b718;

// MIXINS //
// ** Display Flex
@mixin displayFlex($direction, $wrap, $justify, $item, $content) {
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $item;
    align-content: $content;
}

// ** Font

@mixin fontDisplay($family, $size, $weight,$color) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

// ** Animation 
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

@mixin fadein($time) {
  transition: background-color $time ease-in;
  -webkit-transition: background-color $time ease-in;
  -moz-transition: background-color $time ease-in;
  -ms-transition: background-color $time ease-in;
  -o-transition: background-color $time ease-in;
}

@mixin fadeout($time) {
  transition: background-color $time ease-out;
  -webkit-transition: background-color $time ease-out;
  -moz-transition: background-color $time ease-out;
  -ms-transition: background-color $time ease-out;
  -o-transition: background-color $time ease-out;
}