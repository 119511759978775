@import url("https://use.typekit.net/vbs6kjv.css");

// Import
@import './assets/css/index';
@import './assets/css/base';

// Init
body, 
html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: $f-medium;

    background: #FCFDFF;
}

#root, .App {
    height: 100%;
}

#root {
    overflow-y: scroll;
}