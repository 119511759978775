@import url(https://use.typekit.net/vbs6kjv.css);
*, *:before, *:after {
  box-sizing: border-box; }

body {
  font-family: "geomanistmedium"; }

img {
  max-width: 100%;
  height: auto; }

ul {
  padding-left: 0;
  margin: 0;
  list-style: none; }

li {
  list-style: none; }

button {
  border: 0; }

select:focus, button:focus, input:focus {
  outline: none; }

select, button {
  cursor: pointer; }

.container {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative; }

.title-main h4 {
  margin: 0 0 4px;
  font-family: "geomanistbold";
  font-size: 14px;
  text-transform: uppercase;
  color: #E4B716; }

.title-main h1 {
  margin: 0 0 0px;
  font-family: "geomanistultra";
  font-size: 50px;
  text-transform: uppercase;
  color: #545555;
  line-height: 1; }
  .title-main h1:after {
    content: '';
    width: 25%;
    height: 4px;
    display: block;
    margin-top: 4px;
    background: #D1A710; }
    @media (max-width: 820px) {
      .title-main h1:after {
        margin: 10px auto 0; } }

body,
html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "geomanistmedium";
  background: #FCFDFF; }

#root, .App {
  height: 100%; }

#root {
  overflow-y: scroll; }

.loader-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  height: 100%;
  width: 100%; }

.loader {
  min-height: 50vh;
  min-width: 100vw;
  position: absolut;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'geomanistultra';
  src: url('/fonts/geomanist-ultra-webfont.woff2') format('woff2'),
       url('/fonts/geomanist-ultra-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'geomanistmedium';
  src: url('/fonts/geomanist-medium-webfont.woff2') format('woff2'),
       url('/fonts/geomanist-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'geomanistbold';
  src: url('/fonts/geomanist-bold-webfont.woff2') format('woff2'),
       url('/fonts/geomanist-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'geomanistblack';
  src: url('/fonts/geomanist-black-webfont.woff2') format('woff2'),
       url('/fonts/geomanist-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
